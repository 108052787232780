import React, { useContext } from 'react';
import { arrayOf, shape, number } from 'prop-types';
import { Button, Col } from '@thd-olt-component-react/core-ui';
import { useConfigService } from '@thd-nucleus/experience-context';
import { Typography } from '@one-thd/sui-atomic-components';
import { Search, Sort } from '../Filter';
import { AskAQuestion } from '../AskAQuestion/AskAQuestion.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import { Header } from './component/Header';
import { BotPrompt } from '../MagicApron/BotPrompt';
import QAContext from '../../context/QAContext';
import { useQAResult } from './useQAResult';
import { dataModel } from '../dataModel';

export const QAResultHeader = ({ seoResults, seoTotalResults }) => {
  const {
    channel, qaLoading, dispatch, TotalResults, searchText, Results
  } = useContext(QAContext);
  const isMobile = channel === 'mobile';
  const qaResult = useQAResult();
  const magicApronBotEnabled = useConfigService('fs-prop:magic-apron-qa-bot-enabled');

  const results = seoResults || Results;
  const resultsCount = seoResults ? seoTotalResults : TotalResults;

  const handleClearSearchPill = () => {
    dispatch({ action: 'SET_SEARCH_TEXT', value: '' });
  };

  const getMagicApronHeader = () => {
    return (
      <>
        <BotPrompt isMobile={isMobile} />
        <div className="sui-mt-6 sui-flex sui-flex-wrap">
          <span className="sui-mr-1 sui-mb-2">
            <Typography variant={isMobile ? 'body-base' : 'h2'} weight="bold" component="span">
              {resultsCount + ' '}
            </Typography>
            <Typography variant={isMobile ? 'body-base' : 'body-lg'} component="span">
              Question{resultsCount > 1 ? 's' : ''} Posted By Customers
            </Typography>
          </span>
          {!isMobile && (
            <span className="qa-result__search">
              <Search />
            </span>
          )}
        </div>
      </>
    );
  };

  return (
    isMobile
      ? (
        <>
          {magicApronBotEnabled ? getMagicApronHeader() : (
            <div className="qa-result__row">
              <h3 className="qa-result__accordion-header">{resultsCount}</h3>
              <span>&nbsp;{resultsCount > 1 ? 'questions' : 'question'}</span>
            </div>
          )}
          <div className="qa-result__row">
            <Search />
            {searchText && (
              <div className="mobile-search-pill-container">
                <Button className="search-pill" onClick={handleClearSearchPill} filter>
                  {results?.length ? searchText : 'Clear search term'}
                </Button>
              </div>
            )}
          </div>
          {!magicApronBotEnabled && (
            <div className="qa-result__row">
              <AskAQuestion />
            </div>
          )}
          <div className="grid qa-result__row qa-result__sort">
            <PagerDisplayText topPager loading={qaLoading} />
            <Sort />
          </div>
        </>
      )
      : (
        <>
          <Col>
            {magicApronBotEnabled ? getMagicApronHeader()
              : <Header showTitleWithTotalQns seoData={seoTotalResults} />}
          </Col>
          {!qaResult && (
            <div className="qa-result__title">
              <PagerDisplayText topPager loading={qaLoading} />
              {searchText && (
                <Button className="search-pill" onClick={handleClearSearchPill} filter>
                  {results?.length ? searchText : 'Clear search term'}
                </Button>
              )}
              <Sort />
            </div>
          )}
        </>
      )
  );
};

QAResultHeader.displayName = 'QAResultHeader';

QAResultHeader.propTypes = {
  seoResults: arrayOf(shape({})),
  seoTotalResults: number,
};

QAResultHeader.defaultProps = {
  seoResults: null,
  seoTotalResults: null,
};

QAResultHeader.dataModel = dataModel;
