import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import {
  Alert,
  Button,
  IconButton,
  Input,
  Skeleton,
  SkeletonLine,
  Typography,
  FormLabel,
} from '@one-thd/sui-atomic-components';
import { Close } from '@one-thd/sui-icons';
import { useMagicApron } from '../../hooks';
import './BotPrompot.scss';

const USER_QUESTION_CHARACTER_LIMIT = 255;
const keyPressMap = { Enter: false, Shift: false };

export const BotPrompt = ({ isMobile }) => {
  const [userQuestion, setUserQuestion] = useState('');
  const [submittedQuestion, setSubmittedQuestion] = useState('');
  const { error, loading, response } = useMagicApron(submittedQuestion);

  const onUserQuestionSubmit = () => {
    setSubmittedQuestion(userQuestion);
  };
  const onUserQuestionChange = (event) => {
    if (!(keyPressMap.Enter)) {
      setUserQuestion(event.target.value);
    }
  };

  const onUserQuestionKeyPress = (event) => {
    // Keeps track of all currently pressed keys
    keyPressMap[event.key] = event.type === 'keydown';
    if (keyPressMap.Enter) {
      onUserQuestionSubmit();
    }
  };

  const clearPrompt = () => {
    setUserQuestion('');
    setSubmittedQuestion('');
  };

  const gridClasses = classNames('sui-grid', 'sui-grid-cols-1', 'sui-gap-x-4', {
    'lg:sui-grid-cols-2': !!response || loading,
    'lg:sui-grid-cols-3': !response && !loading
  });

  const promptClasses = classNames('sui-mb-2', 'sui-flex', 'sui-flex-wrap', 'sui-gap-2', {
    'sui-col-span-2': !response && !loading,
    'sui-col-span-1': !!response
  });
  const responseClasses = classNames({
    'sui-col-span-2': !response && !loading,
    'sui-col-span-1': !!response
  });

  if (!userQuestion && submittedQuestion) clearPrompt();

  return (
    <div data-component="magic-apron-bot-prompt">
      <div className="sui-mb-4">
        <Typography variant="h5">Looking for quick info about this product? Let us find it for you.</Typography>
      </div>
      <div className={gridClasses}>
        <FormLabel htmlFor="qa-bot-input" className="sui-col-span-full">
          Tell us what you&apos;re looking for
        </FormLabel>
        <div className={promptClasses}>
          <div className="sui-self-start sui-grow">
            <Input
              id="qa-bot-input"
              value={userQuestion}
              onChange={onUserQuestionChange}
              onKeyDown={onUserQuestionKeyPress}
              onKeyUp={onUserQuestionKeyPress}
              fullWidth
              multiline
              placeholder="Ask about this product"
              inputAttributes={{
                maxLength: USER_QUESTION_CHARACTER_LIMIT,
                resize: 'none'
              }}
              endAdornment={!!userQuestion.length && (
                <IconButton
                  icon={Close}
                  size="small"
                  onClick={clearPrompt}
                  aria-label="Clear question"
                />
              )}
            />
          </div>
          {((!loading && !response) || (response && submittedQuestion !== userQuestion)) && (
            <div className={isMobile ? 'sui-grow' : ''}>
              <Button variant="primary" onClick={onUserQuestionSubmit} id="magic_apron_qa_find_it_for_me">
                Find An Answer
              </Button>
            </div>
          )}
        </div>
        <div className={responseClasses}>
          <BotResponse loading={loading} error={error} response={response} />
          <div className="sui-mt-2 sui-mb-2">
            <Typography variant="body-xs" color="subtle" height="tight">
              {/* eslint-disable-next-line max-len */}
              AI-generated from the text of manufacturer documentation. To verify or get additional
              information, please contact The Home Depot customer service.
            </Typography>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

BotPrompt.propTypes = {
  isMobile: bool
};

BotPrompt.defaultProps = {
  isMobile: false
};

const BotResponse = ({ loading, error, response }) => {
  if (loading) {
    return (
      <Skeleton grow>
        <SkeletonLine variant="multi" numberOfLines={3} />
      </Skeleton>
    );
  }

  if (error) {
    return (
      <div className="sui-mt-3">
        <Alert status="error">
          Apologies, we are unable to fetch the requested information at this moment. Please try
          again later.
        </Alert>
      </div>
    );
  }

  if (response) {
    return (
      <Typography variant="body-base" height="tight" component="div">
        <div className="bot-prompt">
          <Markdown>{response.replaceAll(/\n\n/g, '\n\n &nbsp; \n\n')}</Markdown>
        </div>
      </Typography>
    );
  }

  return null;
};

BotResponse.propTypes = {
  loading: bool,
  error: bool,
  response: string
};

BotResponse.defaultProps = {
  loading: false,
  error: false,
  response: ''
};
